import React from 'react';
import '../../App.css';
import { useEffect, useState } from 'react';
import '../pages/Contact.css';

export default function Contact() {
  const SITE_KEY = "6LdKQSgbAAAAAAam8mxjZVNKBzmIukuoqDkXSGXI";
  const [fullname, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
 
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
 
      if (isScriptExist && callback) callback();
    }
 
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
      console.log("Script loaded!");
    });
  }, []);

/*   const handleOnClick = e => {
    e.preventDefault();
    setLoading(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
        submitData(token);
      });
    });
  } */

  const handleOnClick = e => {
    e.preventDefault();
    setLoading(true);
    submitAndSendEmail();
  }  
/* 
  const submitAndSendEmail = () => {
    // Retrieve data from the form.
    const request = new XMLHttpRequest()
    // Convert data to JSON object.
    var jsonData = {}
    jsonData['fullname'] = fullname;
    jsonData['email'] = email;
    //jsonData['message'] = message;
    // Send the data to the Netlify function.
    request.open("POST", "/.netlify/functions/send-email")
    request.send(JSON.stringify(jsonData))
    // Clear the form.
    alert("Email request submitted!")
  }  */

  const submitAndSendEmail = () => {
    // call a backend API to verify reCAPTCHA response
    fetch('/.netlify/functions/send-email', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "fullname": fullname,
        "email": email
      })
    }).then(res => res.json()).then(res => {
      setLoading(false);
      setResponse(res);
    });
  } 

  const submitData = token => {
    // call a backend API to verify reCAPTCHA response
    fetch('http://localhost:4000/verify', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "fullname": fullname,
        "email": email,
        "g-recaptcha-response": token
      })
    }).then(res => res.json()).then(res => {
      setLoading(false);
      setResponse(res);
    });
  }  

  const submitForm = e => {
    // Stop the browser's default behavior.
    e.preventDefault()
    // Retrieve data from the form.
    const formData = new FormData(e.target)
    const request = new XMLHttpRequest()
    // Convert data to JSON object.
    var jsonData = {}
    formData.forEach((value, key) => (jsonData[key] = value))
    // Send the data to the Netlify function.
    request.open("POST", "/.netlify/functions/send-email")
    request.send(JSON.stringify(jsonData))
    // Clear the form.
    alert("Email request submitted!")
    e.target.reset()
  }

  return (
    <>
      <h1 className='contact'>CONTACT</h1>
      <div className="section-container">
          <h1 className="section-container-heading">
              Contact
          </h1>
          <div className="section-container-details">
              <p>Please feel free to contact me if you have any job requirements that you think might suit my skillsets.</p>
          </div>
      </div>       
      <div className='section-container'>
        <form className="contact-form">
          <div className="row">
            <div className="col-25">
              <label htmlFor="fullname">Name</label>
            </div>
            <div className="col-75">
              <input type="text" id="fullname" name="fullname" placeholder="Your name.." />
            </div>
          </div>
          <div className="row">
            <div className="col-25">
              <label htmlFor="email">Email</label>
            </div>
            <div className="col-75">
              <input type="text" id="email" name="email" placeholder="Your email.." />
            </div>
          </div>
          <div className="row">
            <div className="col-25">
              <label htmlFor="message">Message</label>
            </div>
            <div className="col-75">
              <textarea id="message" name="message" placeholder="Write something.."></textarea>
            </div>
          </div>
          <div className="row">
            <button className="btn-submit" onClick={handleOnClick} disabled={loading}>{loading ? 'Submitting...' : 'Submit'}</button>
          </div>
        </form> 
      </div>
    </>   
    );
}
