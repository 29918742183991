import React from 'react'
import './ProfileSection.css';

function ProfileSection() {
    return (
        <>
           <div className="section-container">
               <h1 className="section-container-heading">
                   Profile Summary
               </h1>
                <div className="section-container-details">
                    <p><b>Jijo Jacob</b> currently based in London, United Kingdom, is a software professional with <b>20 years</b> of technical experience in full life cycle of application development. This includes requirement analysis, technical architecting & design, development, database design, executing the projects and implementation.</p>
                    <p>Have great experience in working with leading companies in the United Kingdom, as contractor and in permanent roles. Proficient with wide technologies, expereinced in working as a team/individual and within multicultural environments.</p>
                    <p>Proven track record in technical architecting & design, development, implementation, project management for Mobile Applications using J2ME, Java, Android. Worked on world leading mobile phone applications like Visto Mobile which was used by network providers including Vodafone, T-Mobile. Experience in porting mobile application between different OS/Firmware versions and to new mobile models.</p>
                    <p>Experience of working in Telecommunication sector, with development and delivering of various SMS Short Code based solutions targeting mobile users.</p>
                    <p>Successfully delivered desktop solutions in VB.NET, C#.NET, Visual Basic with MS Access and SQL Server databases. Experienced in web application development with ASP.net, ASP, Java Script, VB Script, XML and Adobe PhotoShop.</p>
                </div>
            </div> 
        </>
    )
}

export default ProfileSection
