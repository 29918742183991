import React from 'react'
import './EducationSection.css';

function EducationSection() {
    return (
        <>
           <div className="section-container">
               <h1 className="section-container-heading">
                   Education
               </h1>
                <div className="section-container-details">
                    <p>Master of Computer Applications (MCA) – Distinction.</p>
                    <p>Bachelor of Science (B Sc Chemistry) – First Class.</p>
                </div>
            </div> 
        </>
    )
}

export default EducationSection