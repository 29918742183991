import React from 'react';
import '../../App.css';
import ProfileSection from '../ProfileSection';
import SkillSection from '../SkillSection';
import EducationSection from '../EducationSection'
import Footer from '../Footer';

function Home() {
  return (
    <>
      <h1 className='home'>PROFILE SUMMARY</h1>
      <ProfileSection/>
      <SkillSection/>
      <EducationSection/> 
      <Footer />
    </>
  );
}

export default Home;
