import React from 'react'
import './Career.css';

function Career() {
    return (
        <>
            <div className="career-container">
                <div className="career-container-heading">
                    <b>Rocaba Group Ltd.</b> <small>East Barnet, London, United Kingdom.</small>
                    <div>Senior Developer, <i>Nov 2011 - Current.</i></div>
                </div>
                
                <div className="career-container-details">
                    <p><b>Responsibilities</b>: Development of ecommerce platform, Webservices, Database designing & administration, SQL Stored Procedure, Database performance tuning.</p>
                    <p><b>Software Used</b>: C#.Net, ASP.NET, MVC, VB.NET, Amazon Web Services, Microsoft SQL Server 2019, Azure, SQL Profiler, Query Analyzer, Jira, Agile.</p>
                    <ul className="career-container-details-list">
                        <li>Development of e-commerce platform to cater United Kingdom, Germany, France, Ireland.</li>
                        <li>Maintenance, Migration, Deployment of .NET Web Application to Amazon EC2 Instances & Dedicated Servers.</li>
                        <li>Architecting WEB API to facilitate Multi-warehouse inventory/dispatch from Germany & UK.</li>
                        <li>Admin system for CRM and order processing.</li>
                        <li>Integrating payment gateways for various countries. SagePay, Stripe, Paypal, BitPay, Sofort.</li>
                        <li>SEO optimisation, Page speed optimization, GDPR, PCI Compliances, Responsive Web Development.</li>
                        <li>Development planning and off-shore team management.</li>
                        <li>Integration with 10+ courier services.</li>
                        <li>Integration with Various third party services Active Campaign, Mandril</li>
                    </ul>
                    <span>
                        Projects
                        <ul className="career-container-details-list">
                            <li>www.carrierbagshop.co.uk. (Ecommerce UK)</li>
                            <li>www.rocabapackaging.co.uk. (Ecommerce UK)</li>
                            <li>www.rocabaverpackung.de. (Ecommerce Germany)</li>
                            <li>www.rocabaemballage.fr. (Ecommerce France)</li>
                            <li>Multiwarehouse inventory module. (WEB API)</li>
                            <li>www.asiand8.com (Website)</li>
                            <li>Asiand8 App (Android Google Play)</li>
                        </ul>
                    </span>
                </div>
            </div> 
            <div className="career-container">
                <div className="career-container-heading">
                    <b>EM Trade Solutions.</b> <small>Eltham, London, United Kingdom.</small>
                    <div>Senior Developer, <i>May 2009 - Oct 2011.</i></div>
                </div>
                
                <div className="career-container-details">
                    <p><b>Responsibilities</b>: Development of site, data layer, webservices, Database designing & administration, SQL Stored Procedure, Database performance tuning.</p>
                    <p><b>Software Used</b>: VB.NET, C#.Net, ASP.NET, Netsuite, SQL Server, SQL Management Studio 2008, SQL Examiner, Query Analyzer.</p>
                    <ul className="career-container-details-list">
                        <li>VB.NET/ASP.NET coding for www.emarket.com, the first 'total solutions' online exchange for the FMCG industry.</li>
                        <li>Database designing & administration, SQL Stored Procedure, Database performance tuning for the emarket FMCG platform.</li>
                        <li>Played a key role in business discussions and implementation of key features of the trading platform.</li>
                        <li>Developed a webservice system to sync the emarket.com data with NetSuite, an integrated business software suite that includes accounting, CRM, ERP and e-commerce.</li>
                    </ul>
                    <span>
                        Projects
                        <ul className="career-container-details-list">
                            <li>www.emarket.com. (FMCG Ecommerce Website)</li>
                            <li>emarket Sync/Workflow. (Sync Between Netsuite & Web Platforms)</li>
                            <li>emarket Rule Engine. (Duty calculation)</li>
                        </ul>
                    </span>
                </div>
            </div> 
            <div className="career-container">
                <div className="career-container-heading">
                    <b>Visto Corporation.</b> <small>London, United Kingdom.</small>
                    <div>Senior Programmer, <i>Oct 2007 - Mar 2009</i></div>
                </div>
                
                <div className="career-container-details">
                    <p><b>Responsibilities</b>: J2ME Coding, Performance Optimization, Porting on New devices.</p>
                    <p><b>Software Used</b>: Java J2ME, XML, Netbean 6.5 & 5.5, Perforce, Yourkit Java Profiler.</p>
                    <ul className="career-container-details-list">
                        <li>J2ME coding for Visto Mobile 6.0 (Social Networking), Visto Mobile 5.9, 5.7 (Email Client) used by Vodafone, T-Mobile, AT&T, TELUS, Rogers.</li>
                        <li>Involved in defect fixing, Unit Testing, Integration Testing, code review and project functionality discussions.</li>
                        <li>Optimized/Refractor the existing code for better performance and ported the existing application to more mobile platforms, which were not supported previously. This helped Visto to offer the application more devices from Nokia, Sony Ericsson JP7, JP8, Motorola, LG and Samsung.</li>
                        <li>Used profiling to find any memory leaks and rewritten the code for better memory management.</li>
                        <li>Involved in Custom UI development for Visto Mobile 6.0. Which helped the application to support touch screen.</li>
                        <li>Conducted feasibility studies on various possible projects proposed by clients.</li>
                    </ul>
                    <span>
                        Projects
                        <ul className="career-container-details-list">
                            <li>Visto Mobile 6.0. (Social Networking)</li>
                            <li>Visto Mobile 5.9. (Email Client)</li>
                            <li>Visto Mobile 5.7. (Email Client)</li>
                        </ul>
                    </span>
                </div>
            </div> 
            <div className="career-container">
                <div className="career-container-heading">
                    <b>Abbott Laboratories.</b> <small>Kent, United Kingdom.</small>
                    <div>Senior Programmer (Contract), <i>July 2007 - Sept 2007</i></div>
                </div>
                
                <div className="career-container-details">
                    <p><b>Responsibilities</b>: Application Design, Coding, defining/implementing SQL Server Stored Procedure.</p>
                    <p><b>Software Used</b>: VB.NET, Microsoft SQL Server 2005, MS Visual Source Safe.</p>
                    <ul className="career-container-details-list">
                        <li>Worked on Electronic Batch Records a suite of applications for Abbott Laboratories.</li>
                        <li>Developed PBR Potency Adjust using VB.net with Microsoft SQL Server as backend.</li>
                        <li>Responsible in creating stored procedures in Microsoft SQL Server.</li>
                        <li>Integrated PBR potency adjust to interact with EBR project using XML.</li>
                        <li>Involved in discussion on database designing/requirements.</li>
                    </ul>
                    <span>
                        Projects
                        <ul className="career-container-details-list">
                            <li>PBR Potency Adjust. (Pharmaceutical Manufacturing)</li>
                        </ul>
                    </span>
                </div>
            </div> 
            <div className="career-container">
                <div className="career-container-heading">
                    <b>Integral Technologies Ltd.</b> <small>Devon, United Kingdom.</small>
                    <div>Senior Programmer (Contract), <i>May 2007 – June 2007</i></div>
                </div>
                
                <div className="career-container-details">
                    <p><b>Responsibilities</b>: Web/Application designing & Coding, Database design.</p>
                    <p><b>Software Used</b>: C#.net, ASP.net, SQL Server 2005, and Crystal Reports.</p>
                    <ul className="career-container-details-list">
                        <li>Worked on Employee Intranet System an intranet web application in ASP.net involving Project management, scheduling, resource allocation and project monitoring.</li>
                        <li>Developed the email communication and notification system of the project.</li>
                        <li>Responsible in Database design, testing & defect fixing.</li>
                    </ul>
                    <span>
                        Projects
                        <ul className="career-container-details-list">
                            <li>Employee Intranet System. (Intranet Application)</li>
                        </ul>
                    </span>
                </div>
            </div> 
            <div className="career-container">
                <div className="career-container-heading">
                    <b>Trane (Colchester) Limited.</b> <small>Essex, United Kingdom.</small>
                    <div>Senior Programmer (Contract), <i>Oct 2006-Apr 2007.</i></div>
                </div>
                
                <div className="career-container-details">
                    <p><b>Responsibilities</b>: Involved in full life cycle of this project development. Requirement Analysis, Product architecture, development, database design, optimization, testing and release.</p>
                    <p><b>Software Used</b>: VB.Net, SQL Server 2000/MS Access, XML, Crystal Reports.</p>
                    <ul className="career-container-details-list">
                        <li>Worked closely with the client for requirement analysis, software selection, project design for Factory Mounted Control Wizard.</li>
                        <li>Analyzed the functionality of the existing Excel application and ported it into .Net using SQL Server/MS Access.</li>
                        <li>Developed XML based data transferring between the in house/client side application.</li>
                        <li>Designed various reports in Business Object Crystal Reports.</li>
                        <li>Implemented import/export of data between applications.</li>
                        <li>Database design in MS SQL Server and MS access.</li>
                        <li>Successfully delivered the application within the planned time frame.</li>
                    </ul>
                    <span>
                        Projects
                        <ul className="career-container-details-list">
                            <li>Factory Mounted Control Wizard. (Manufacturing Internal Application)</li>
                        </ul>
                    </span>
                </div>
            </div> 
            <div className="career-container">
                <div className="career-container-heading">
                    <b>Media Master.</b> <small>Dubai, United Arab Emirates.</small>
                    <div>Project Lead / Programmer Analyst, <i>Aug 2003 – Aug 2006.</i></div>
                </div>
                
                <div className="career-container-details">
                    <p><b>Responsibilities</b>: Application development, Database designing, Mobile application/database design and development, Content/Mobile Website development/maintenance.</p>
                    <p><b>Software Used</b>: J2ME, VB6.0, VB.Net, ASP, JavaScript, CSS, SQL Server 2000/MS Access, XML, Crystal Reports.</p>
                    <ul className="career-container-details-list">
                        <li>Successfully lead a team, which includes application developers, web developers, and web designers.</li>
                        <li>Developed SMS Messaging Application & Mobi Chat, which generates 60% of the total profit generated by the company.</li>
                        <li>Responsible for the creation of company web sites using HTML, ASP, JavaScript, CSS, SQL Server and MS Access.</li>
                        <li>Developed a web based application which allow the clients to send marketing/information SMS to their customers.</li>
                        <li>Designed, coded and released company's first J2ME Mobile Application Media News which increased customer base and marked entry into Mobile Application development.</li>
                        <li>Developed MM Share Trade & Stock quote Mobile application that gets updated information on DFM (Dubai Financial Market) and ADSM (Abu Dhabi Security Market).</li>
                        <li>Migrated VB6.0 application to .Net and ASP website to ASP.net.</li>
                        <li>Database administration, back/restore, Carried out Database updating and data migration to new version.</li>
                    </ul>
                    <span>
                        Projects
                        <ul className="career-container-details-list">
                            <li>MM Share Trade. (Mobile Application)</li>
                            <li>Stock Quote Application. (SMS Based)</li>
                            <li>Media News. (Mobile Application)</li>
                            <li>Media Master. (Website/Mobile Website)</li>
                            <li>Messaging Application. (SMPP 3.4)</li>
                            <li>Mobi Chat. (SMS Chat)</li>
                        </ul>
                    </span>
                </div>
            </div> 
            <div className="career-container">
                <div className="career-container-heading">
                    <b>Digitronics Info Systems.</b> <small>Cochin, India.</small>
                    <div>Programmer, <i>June 2001 – May 2003.</i></div>
                </div>
                
                <div className="career-container-details">
                    <p><b>Responsibilities</b>: Application development, Database Designing, Website development/Maintenance.</p>
                    <p><b>Software Used</b>: VB6.0, ASP, JavaScript, CSS, SQL Server 2000/MS Access.</p>
                    <ul className="career-container-details-list">
                        <li>Involved in MediSoft Inventory, Stock, Financial System which is in use by 165+ pharmacies in Kerala.</li>
                        <li>Involved in client installation and user support.</li>
                        <li>Key role as application developer/database designer for the project HRD Automation System.</li>
                        <li>Involved in online module to be used within HRD Automation System.</li>
                    </ul>
                    <span>
                        Projects
                        <ul className="career-container-details-list">
                            <li>MediSoft. (Pharmacy Invetory/Accounts/Sales)</li>
                            <li>HRD Automation System</li>
                        </ul>
                    </span>
                </div>
            </div> 
        </>
    )
}

export default Career