import React from 'react';

function ProjectItem(props) {
  return (
    <>
      <li className='cards__item'>
        <figure className='cards__item__pic-wrap' data-category={props.label}>
        <img
            className='cards__item__img'
            alt='Travel'
            src={props.src}
        />
        </figure>
      </li>
    </>
  );
}

export default ProjectItem;