import React from 'react'
import './SkillSection.css';

function SkillSection() {
    return (
        <>
           <div className="section-container">
               <h1 className="section-container-heading">
                   Key Technical Skills
               </h1>
                <div className="section-container-details">
                    <p>ASP.NET, .Net Core, WebAPI, MVC, C#, VB.Net (Web/Desktop development).</p>
                    <p>Amazon Webservices, EC2 Instance management, Load Balancer.</p>
                    <p>Mobile Development with Android, Kotlin, J2ME, Google Play Console.</p>
                    <p>Database Microsoft SQL Server, Amazon RDS, Azure.</p>
                    <p>Server Management, Security/SSL, Database optimization/Management, Server Migration and Database Migration.</p>
                    <p>SEO Optimization with Google Light House, Google PLA & Schema for Google Adwords.</p>
                    <p>Source control using GIT, SVN, MS Visual Source Safe. Amazon CodeCommit and other repository hosting services.</p>
                    <p>Agile Scrum, Jira</p>
                </div>
            </div> 
            <div className="section-container">
               <h1 className="section-container-heading">
                   Other Technical Skills
               </h1>
                <div className="section-container-details">
                    <p>React, Ajax, HTML, WML, Java Script, VB Script, CSS, SCSS. (Web Technologies)</p>
                    <p>SMPP, Derdack SMS SDK, ActiveExperts SMS Toolkit. (Short Messaging)</p>
                    <p>Sage Pay, Stripe, Paypal, Bitpay, Sofort. (Payment Gateways)</p>
                    <p>Active Campaign, Mandrill, MailChimp. (Communication)</p>
                    <p>SEO MOZ, Google Page Speed, Google Search Console, Google Analytics, Adwords (SEO).</p>
                    <p>SQL Profiler. (Database)</p>
                    <p>CPanel, Wordpress, Amazon Light House. (Web Hosting)</p>
                </div>
            </div>             
        </>
    )
}

export default SkillSection