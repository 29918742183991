import React from 'react'
import './ProjectSection.css';
import './Cards.css';
import ProjectItem from './ProjectItem';
import ProjectCBS from '../images/carrier-bag-shop.webp';
import ProjectRPUK from '../images/rocaba-packaging.webp';
import ProjectRPIE from '../images/rocaba-pack.webp';
import ProjectRPDE from '../images/rocaba-verpackung.webp';
import ProjectRPFR from '../images/rocaba-emballage.webp';
import ProjectAdmin from '../images/carrier-bag-shop-admin.webp';
import ProjectAD8 from '../images/asiand8.webp';
import ProjectAD8Events from '../images/asiand8-events.webp';
import ProjectAD8Register from '../images/asiand8-register.webp';
import ProjectAD8App03 from '../images/asiand8-app-menu.webp';
import ProjectAD8App04 from '../images/asiand8-app-settings.webp';
import ProjectEmrt from '../images/e-market.webp';
import ProjectEmrtPro from '../images/e-market-products.webp';
import ProjectVM60 from '../images/visto-mobile-01.webp';
import ProjectVM602 from '../images/visto-mobile-02.webp';
import ProjectTR01 from '../images/trane-control-wizard-01.webp';
import ProjectTR02 from '../images/trane-control-wizard-02.webp';
import ProjectTR03 from '../images/trane-control-wizard-03.webp';
import ProjectTR04 from '../images/trane-control-wizard-04.webp';



function ProjectSection() {
    return (
        <>
            <div className="project-container">
                <div className="project-container-heading">
                    <b>www.carrierbagshop.co.uk.</b> (Ecommerce Website UK)<br/>
                    <b>www.rocabapack.ie.</b> (Ecommerce Website Ireland)<br/>
                    <b>www.rocabapackaging.co.uk</b> (Ecommerce Website UK)
                    <div><i>Rocaba Group Ltd.</i></div>
                </div>
                <div className="project-container-details">
                    <p><b>Responsibilities</b>: Development of ecommerce website. Requirement Analysis, Project Planning, Functionality Development, Deployment, Security & Server Maintenance</p>
                    <p><b>Software Used</b>: C#.Net, VB.NET, ASP.NET, Microsoft SQL Server 2019, SVN, Amazon CodeCommit (GIT).</p>
                    <ul className="project-container-details-list">
                        <li>SEO optimised, high performance website.</li>
                        <li>Admin area to support all business departments including customer service, purchasing, accounts, marketing and warehouse department</li>
                        <li>Admin section includes Product/Inventory management, CRM, Offline/Bespoke product ordering system.</li>
                        <li>Hosted on dedicated windows server 2019, with Microsoft SQL Server 2019.</li>
                        <li>Multiple Courier Integration.</li>
                    </ul>
                    <div className='cards'>
                        <div className='cards__container'>
                            <div className='cards__wrapper'>
                                <ul className='cards__items'>
                                    <ProjectItem
                                        src={ProjectRPUK}
                                        label='Rocaba Packaging'
                                        /> 
                                    <ProjectItem
                                        src={ProjectCBS}
                                        label='Carrier Bag Shop'
                                        />
                                    <ProjectItem
                                        src={ProjectRPIE}
                                        label='Rocaba Pack (Ireland)'
                                        />                                                                                   
                                </ul>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div> 
            <div className="project-container">
                <div className="project-container-heading">
                    <b>www.rocabaverpackung.de.</b> (Ecommerce Website Germany)<br/>
                    <b>www.rocabaemballage.fr.</b> (Ecommerce Website France)
                    <div><i>Rocaba Group Ltd.</i></div>
                </div>
                <div className="project-container-details">
                    <p><b>Responsibilities</b>: Development, Planning and maintenance of Amazon EC2 Instance.</p>
                    <p><b>Software Used</b>: C#.Net, VB.NET, ASP.NET, MVC, Web API, Amazon Web Services, Amazon RDS.</p>
                    <ul className="project-container-details-list">
                        <li>Hosted on AWS EC2 instance.</li>
                        <li>REST API for inventory from German warehouse.</li>
                        <li>GDPR & PCI Complaince.</li>                        
                        <li>German website can take orders from United Kingdom warehouse inventory.</li>
                        <li>German warehouse providing inventory for French and Ireland web orders.</li>
                        <li>Sofort payment to support German customers.</li>
                        <li>Search engine optimization for Germany, Austria, Belgium, Denmark, Luxembourg, Netherlands.</li>
                    </ul>
                    <div className='cards'>
                        <div className='cards__container'>
                            <div className='cards__wrapper'>
                                <ul className='cards__items'>
                                    <ProjectItem
                                        src={ProjectRPDE}
                                        label='Rocaba Verpackung (Germany)'
                                        />
                                    <ProjectItem
                                        src={ProjectRPFR}
                                        label='Rocaba Eemballage (France)'
                                        />    
                                    <ProjectItem
                                        src={ProjectAdmin}
                                        label='Admin Area'
                                        />                                                                                                                     
                                </ul>
                            </div>
                        </div>
                    </div>                      
                </div>
            </div> 
            <div className="project-container">
                <div className="project-container-heading">
                    <b>www.asiand8.com.</b> (Events & Dating Website)
                    <div><i>Rocaba Group Ltd.</i></div>
                </div>
                <div className="project-container-details">
                    <p><b>Responsibilities</b>: Development, Planning, Amazon EC2 Instance.</p>
                    <p><b>Software Used</b>: C#.Net, ASP.NET, Amazon Web Services, Azure, Amazon S3, Active Campaign.</p>
                    <ul className="project-container-details-list">
                        <li>Single & Subscription payment via SagePay, Paypal, Stripe, Apple Pay.</li>
                        <li>Events booking, Management.</li>
                        <li>Automated email campaigns, discounts, notification using Active Campaign.</li>
                    </ul>
                    <div className='cards'>
                        <div className='cards__container'>
                            <div className='cards__wrapper'>
                                <ul className='cards__items'>
                                    <ProjectItem
                                        src={ProjectAD8}
                                        label='AsianD8'
                                        />
                                    <ProjectItem
                                        src={ProjectAD8Events}
                                        label='AsianD8 Events'
                                        />    
                                    <ProjectItem
                                        src={ProjectAD8Register}
                                        label='AsianD8'
                                        />                                                                                                                     
                                </ul>
                            </div>
                        </div>
                    </div>                      
                </div>
            </div> 
            <div className="project-container">
                <div className="project-container-heading">
                    <b>Asiand8 WEB API.</b> (Web API)
                    <div><i>Rocaba Group Ltd.</i></div>
                </div>
                <div className="project-container-details">
                    <p><b>Responsibilities</b>: Development, Planning, Deployment to Amazon EC2 Instance.</p>
                    <p><b>Software Used</b>: C#.Net, ASP.NET, Amazon Web Services, Azure, Amazon S3.</p>
                    <ul className="project-container-details-list">
                        <li>IOS & Android App make use of this web api for registration, payment, subscription.</li>
                        <li>API provides data sync between website and the mobile App.</li>
                        <li>User authentication on mobile devices.</li>
                    </ul>
                </div>
            </div>            
            <div className="project-container">
                <div className="project-container-heading">
                    <b>Asiand8 App.</b> (Android App)
                    <div><i>Rocaba Group Ltd.</i></div>
                </div>
                <div className="project-container-details">
                    <p><b>Responsibilities</b>: Development, Deplyment to Google Play.</p>
                    <p><b>Software Used</b>: Android (Java), Android Studio.</p>
                    <ul className="project-container-details-list">
                        <li>Android APP providing all features of asiand8.co.uk site to mobile devices.</li>
                        <li>API to sync data between website and mobile.</li>
                    </ul>
                    <div className='cards'>
                        <div className='cards__container'>
                            <div className='cards__wrapper'>
                                <ul className='cards__items'>
                                    <ProjectItem
                                        src={ProjectAD8App04}
                                        label='AsianD8 Android'
                                        />
                                    <ProjectItem
                                        src={ProjectAD8App03}
                                        label='AsianD8 Android'
                                        />   
                                </ul>
                            </div>
                        </div>
                    </div>                     
                </div>
            </div>             
            <div className="project-container">
                <div className="project-container-heading">
                    <b>www.emarket.com FMCG Trading.</b>
                    <div><i>EM Trade Solutions.</i></div>
                </div>
                <div className="project-container-details">
                    <p><b>Responsibilities</b>: Coding Data access layer within web application, Database designing & administration, SQL Stored Procedure, Database performance tuning.</p>
                    <p><b>Software Used</b>: VB.NET/ASP.NET.</p>
                    <ul className="project-container-details-list">
                        <li>emarket explodes the current barriers to trade by enabling its members to transact Europe-wide through a secure, neutral, real-time platform.</li>
                        <li>Supply chain solutions including: door-to-door delivery; inspection of goods; pricing; payment; foreign exchange and credit.</li>
                        <li>emarket displays all offers on a uniquely member specific basis, with the price including the cost of delivery and all applicable taxes and duties, leaving buyers to simply click and buy.</li>
                        <li>Sellers can target specific geographic markets or particular formats with specialized promotions and offers.</li>
                    </ul>
                    <div className='cards'>
                        <div className='cards__container'>
                            <div className='cards__wrapper'>
                                <ul className='cards__items'>
                                    <ProjectItem
                                        src={ProjectEmrt}
                                        label='Emarket FMCG Trading'
                                        />
                                    <ProjectItem
                                        src={ProjectEmrtPro}
                                        label='FMCG Market Place'
                                        />                                                                                                                                                    
                                </ul>
                            </div>
                        </div>
                    </div>                     
                </div>
            </div>             
            <div className="project-container">
                <div className="project-container-heading">
                    <b>Duty/Eco-tax Rule Engine.</b>
                    <div><i>EM Trade Solutions.</i></div>
                </div>
                <div className="project-container-details">
                    <p><b>Responsibilities</b>: Design, Development and implementation of Business Rule Engine.</p>
                    <p><b>Software Used</b>: C#.net, Microsoft Workflow Foundation, MS SQL Server 2005.</p>
                    <ul className="project-container-details-list">
                        <li>Emarket rule engine is responsible for duty/ecotax calculation on FMCG products for 27 countries.</li>
                        <li>These rule engines make use of Microsoft foundation workflow.</li>
                        <li>This helps the business team to add/edit rules for various countries quickly without the requirement of code changes.</li>
                    </ul>
                </div>
            </div>             
            <div className="project-container">
                <div className="project-container-heading">
                    <b>Emarket Netsuite Sync/Logistic Workflow.</b>
                    <div><i>EM Trade Solutions.</i></div>
                </div>
                <div className="project-container-details">
                    <p><b>Responsibilities</b>: Development of Netsuite/DB sync using web services and Logistic workflow, an XML communication system with Transport Company ‘Geodis’.</p>
                    <p><b>Software Used</b>: C#.net, VB.net, MS SQL Server 2005.</p>
                    <ul className="project-container-details-list">
                        <li>Webservice system to sync the emarket.com data with NetSuite, an integrated business software suite that includes accounting, CRM, ERP and e-commerce.</li>
                        <li>Generating XML based Transport order and real time logistic status updates to/from Transport companies.</li>
                        <li>Automated email alert system, purchase order and invoices.</li>
                    </ul>
                </div>
            </div>             
            <div className="project-container">
                <div className="project-container-heading">
                    <b>Visto Mobile 6.0, Social Networking.</b>
                    <div><i>Visto Corporation.</i></div>
                </div>
                <div className="project-container-details">
                    <p><b>Responsibilities</b>: J2ME Coding, Performance Optimization, Porting on New devices.</p>
                    <p><b>Software Used</b>: Java J2ME, Netbean 6.5, Perforce.</p>
                    <ul className="project-container-details-list">
                        <li>Real-time push information about your most important contacts and social network relationships, with presence and notifications for your “Favorites”.</li>
                        <li>A contextual launch point to email, IM, the web and other device applications.</li>
                        <li>People-centric views that keeps you up-to-date on your friends’ activities.</li>
                        <li>A personal “Me” profile you can share with other mobile users so they are automatically kept up-to-date on your status and public profile information.</li>
                        <li>Integration with Photobucket and Flickr so you can instantly share pictures with friends and you can see when your friends share pictures with you.</li>
                        <li>Integration with Facebook to posts, messages, event/invites, instantly get pushed to your phone.</li>
                    </ul>
                    <div className='cards'>
                        <div className='cards__container'>
                            <div className='cards__wrapper'>
                                <ul className='cards__items'>
                                    <ProjectItem
                                        src={ProjectVM60}
                                        label='Visto Mobile'
                                        />    
                                    <ProjectItem
                                        src={ProjectVM602}
                                        label='Visto Mobile'
                                        />                                                                                                                       
                                </ul>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>             
            <div className="project-container">
                <div className="project-container-heading">
                    <b>Visto Mobile 5.9, 5.7, Email Client.</b>
                    <div><i>Visto Corporation.</i></div>
                </div>
                <div className="project-container-details">
                    <p><b>Responsibilities</b>: J2ME Coding, Memory Profiling & Performance Optimization, Porting on New devices.</p>
                    <p><b>Software Used</b>: Java J2ME, Netbean 5.5, Yourkit Java Profiler, Perforce.</p>
                    <ul className="project-container-details-list">
                        <li>Access multiple email accounts, including the most popular Internet email such as Gmail, Yahoo! Mail and MSN/Hotmail, and MS Exchange–each with a separate mailbox on the phone.</li>
                        <li>Get full PIM integration with leading systems including MSN/Hotmail, Yahoo!, and MS Exchange–sync contacts and calendar entries to phone.</li>
                        <li>Can Set the days and hours when email is delivered using VISTO Mobile’s connection schedule feature and manage data plan costs.</li>
                        <li>A personal “Me” profile you can share with other mobile users so they are automatically kept up-to-date on status and public profile information.</li>
                        <li>VISTO Mobile Email together with VISTO Mobile iPortal helps to get web-based access to information, making it easy to edit and backup important data for safe storage.</li>
                    </ul>
                </div>
            </div>
            <div className="project-container">
                <div className="project-container-heading">
                    <b>PBR Potency Adjust.</b>
                    <div><i>Abbott Laboratories.</i></div>
                </div>
                <div className="project-container-details">
                    <p><b>Responsibilities</b>: Application design, Coding & writing SQL Stored Procedure.</p>
                    <p><b>Software Used</b>: VB.NET, SQL Server 2005, MS Visual Source Safe.</p>
                    <ul className="project-container-details-list">
                        <li>PBR Potency Adjust along with EBR helped in reducing the amount of paper works involved in various stages during manufacturing. Which has contributed the manufacturing unit to go green.</li>
                        <li>Made way for better communication, speeding up allocation & verification process which inturn increased the total manufacturing output.</li>
                        <li>A centralised system resulted in better co-ordination between different unit. The new system helped to avoid human errors which helped to reduce financial loss caused by rejected products.</li>
                    </ul>
                </div>
            </div>             
            <div className="project-container">
                <div className="project-container-heading">
                    <b>Employee Intranet System.</b>
                    <div><i>Integral Technologies Ltd.</i></div>
                </div>
                <div className="project-container-details">
                    <p><b>Responsibilities</b>: Application design & Coding, Database design.</p>
                    <p><b>Software Used</b>: C#.net, ASP.net, SQL Server 2005, and Crystal Reports.</p>
                    <ul className="project-container-details-list">
                        <li>A complete system which manages employee details, resource allocation, project and defect tracking.</li>
                        <li>Facility to document entire project from requirement analysis to product release.</li>
                        <li>Intranet access to all employee details. Employee can access personal details, invoices, submit expenses and other request like leave applications.</li>
                        <li>Defect tracking system includes defect raising, assignment, approval, rejection.</li>
                    </ul>
                </div>
            </div>
            <div className="project-container">
                <div className="project-container-heading">
                    <b>Factory Mounted Controls Wizard.</b>
                    <div><i>Trane (Colchester) Limited.</i></div>
                </div>
                <div className="project-container-details">
                    <p><b>Responsibilities</b>: Involved in full life cycle of this project development. Requirement Analysis, Product architecture, development, database design, optimization, testing and release.</p>
                    <p><b>Software Used</b>: VB.Net, SQL Server 2000/MS Access, XML, Crystal Reports.</p>
                    <ul className="project-container-details-list">
                        <li>TCW comes with an easy to use, user interface which makes it easy to use with a minimum training.</li>
                        <li>Dynamic component selection ensure that the component location corresponds to the agreed customer requirement.</li>
                        <li>Extensive verification routine ensure that the controls selection will be correct even in the very first time.</li>
                        <li>Powerfull report generator creates quotes, material costing, labour cost and will give a clear picture of the margin of profit for a given quote.</li>
                        <li>Easier component addition and modification makes the price changing much easier and is completely database driven.</li>
                        <li>Export function allows to save the project in Excel, PDF or Microsoft word format.</li>
                        <li>Facility customise the product for multiple currency. Which makes it flexible to use in different company locations.</li>
                        <li>Stable software architecture enables safe recoverable projects not to be lost.</li>
                    </ul>
                    <div className='cards'>
                        <div className='cards__container'>
                            <div className='cards__wrapper'>
                                <ul className='cards__items'>
                                    <ProjectItem
                                        src={ProjectTR01}
                                        label='Manufacturing'
                                        />
                                    <ProjectItem
                                        src={ProjectTR02}
                                        label='Dynamic Components'
                                        />  
                                    <ProjectItem
                                        src={ProjectTR03}
                                        label='Component Match'
                                        />  
                                    <ProjectItem
                                        src={ProjectTR04}
                                        label='Reports'
                                        />                                                                                                                                                                                            
                                </ul>
                            </div>
                        </div>
                    </div>                     
                </div>
            </div> 
            <div className="project-container">
                <div className="project-container-heading">
                    <b>MM Share Trade Mobile Application.</b>
                    <div><i>Media Master.</i></div>
                </div>
                <div className="project-container-details">
                    <p><b>Responsibilities</b>: Mobile application/database design and development.</p>
                    <p><b>Software Used</b>: J2ME, XML.</p>
                    <ul className="project-container-details-list">
                        <li>MM Share application helps the user to get updates on stock rates from Dubai Financial Market and Abudhabi Stock Market.</li>
                        <li>Application stores the previous quotes for all stocks and helps the user to identify the market trends any any stocks. It also shows an easy graphical representation for the rise and fall of the share values.</li>
                        <li>User can initiate share trading through brokers and will get updates on the trading status.</li>
                        <li>User can set alerts for stocks of their interest and receive notification.</li>
                    </ul>
                </div>
            </div>  
            <div className="project-container">
                <div className="project-container-heading">
                    <b>Stock Quote Application.</b>
                    <div><i>Media Master.</i></div>
                </div>
                <div className="project-container-details">
                    <p><b>Responsibilities</b>: Application design & Coding, Database design.</p>
                    <p><b>Software Used</b>: VB6.0, SMPP 3.4, VB.Net (Migrated), SQL Server 2000, XML, Crystal Reports.</p>
                    <ul className="project-container-details-list">
                        <li>Stock Quote application is an SMS based service on stock rates.</li>
                        <li>User can get rates of all stocks of DFM/ADSM stock market, or can get more details of any stock including current rate, previous day rate, change of value.</li>
                        <li>User can set lower limit and upper limit for any stock and will be notified through SMS when the rates reaches this limits.</li>
                        <li>SMS can be either in English or Arabic (Unicode) which can be specified along with the request.</li>
                    </ul>
                </div>
            </div> 
            <div className="project-container">
                <div className="project-container-heading">
                    <b>Media News Mobile Application.</b>
                    <div><i>Media Master.</i></div>
                </div>
                <div className="project-container-details">
                    <p><b>Responsibilities</b>: Development of RSS newsreader using XML parser. Server side programming with ASP and SQL Server.</p>
                    <p><b>Software Used</b>: J2ME, ASP, SQL Server 2000.</p>
                    <ul className="project-container-details-list">
                        <li>Media News make use of RSS2.0 format to deliver news updates to the mobile handset.</li>
                        <li>User can add any RSS 2.0 feeds to the application and get updates.</li>
                        <li>Application support multiple languages.</li>
                    </ul>
                </div>
            </div>   
            <div className="project-container">
                <div className="project-container-heading">
                    <b>Media Master Website/Mobile Website.</b>
                    <div><i>Media Master.</i></div>
                </div>
                <div className="project-container-details">
                    <p><b>Responsibilities</b>: Key member for developing media master mobile content website. Responsible for Server side development with ASP, Database designing in SQL Server, Involved in mobile website development with WML, XHTML.</p>
                    <p><b>Software Used</b>: ASP, SQL Server 2000, WAP, CSS, Java Script, WML, and XHTML.</p>
                    <ul className="project-container-details-list">
                        <li>Website provides mobile contents including ringtones, polytones, wallpaper, themes and mobile games.</li>
                        <li>The site also allows registered users to buy/send SMS for marketting and customer support. The user can upload mobile numbers from any text file or excel file. This data can be stored to be used later for sending bulk sms.</li>
                        <li>Registered user can keep track on available credits and get detailed reports on all outgoing SMS.</li>
                        <li>Updates on Auctions, contests and sports are available to the user through the site.</li>
                    </ul>
                </div>
            </div> 
            <div className="project-container">
                <div className="project-container-heading">
                    <b>iSindbad Messaging Application.</b>
                    <div><i>Media Master.</i></div>
                </div>
                <div className="project-container-details">
                    <p><b>Responsibilities</b>: Involved in complete Application development, Database design, maintenance, and Database upgrading.</p>
                    <p><b>Software Used</b>: VB 6.0, SQL Server 2000, Derdack SMS SDK.</p>
                    <ul className="project-container-details-list">
                        <li>iSindbad Messaging apllication make use of Derdack SMS SDK to send and receive SMS through companies short code.</li>
                        <li>Application uses SMPP 3.4 to bind to SMSC as a tranciever, transmitter or receiver.</li>
                        <li>iSindbad is capable of sending WAP push, RTTL Ringtones and picture messages.</li>
                        <li>Advanced spam control system and auto flood control makes the system more reliable.</li>
                        <li>Provides bulk sms with scheduling facility.</li>
                    </ul>
                </div>
            </div> 
            <div className="project-container">
                <div className="project-container-heading">
                    <b>MediSoft Pharmacy Inventory, Stock, Financial System.</b>
                    <div><i>Media Master.</i></div>
                </div>
                <div className="project-container-details">
                    <p><b>Responsibilities</b>: Played key role in application design, development, testing and implementation. Involved in client installation and user support.</p>
                    <p><b>Software Used</b>: VB 6.0, SQL Server 2000, and MS Access.</p>
                    <ul className="project-container-details-list">
                        <li>Medisoft is a complete package which includes Inventory, Stock and Finance.</li>
                        <li>Powerfull reports for purchase, sales and stock. Also include year end statements with general ledger, balance sheet, profit & loss statements.</li>
                        <li>User privileges and admin functionality with various levels of privilege settings. All transactions by a user is recorded for later reviews.</li>
                        <li>The application itself come with 100000+ medicine names and substitutes. Easy user interface and navigation makes the training for anyone with less computer knowledge more efficient.</li>
                    </ul>
                </div>
            </div>                                                                                     
        </>
    )
}

export default ProjectSection